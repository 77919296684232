/* @flow */
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

export default function configureStore(initialState) {
	const store = createStore(function(){return initialState}, initialState, compose(
		applyMiddleware(thunk),
		(typeof window !== 'undefined' && window.devToolsExtension) ? window.devToolsExtension() : fn => fn
	));


	return store;
}
