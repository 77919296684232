import React from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";


function E404() {
	document.documentElement.classList.remove("nav-open");
	React.useEffect(() => {
		document.body.classList.add("index");
		return function cleanup() {
			document.body.classList.remove("index");
		};
	});
	return (
		<>
			<IndexNavbar />
			<div className="page-header error404-container">
				<main class="error404-bsod container error404-container error404-box">
					<h1 class="neg title"><span class="bg">Error - 404</span></h1>
					<p>An error has occured, to continue:</p>
					<p>* Page Not Found.<br />* Return to our homepage.</p>
				</main>
			</div>
		</>
	);
}

export default E404;