
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { AppContainer } from 'react-hot-loader'
import App from './App';
import createStore from './createStore';


const store = createStore({});

ReactDOM.render(
	<AppContainer>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</AppContainer>,
	document.getElementById('app')
);


