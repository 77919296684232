import React from "react";
import { Route, Switch } from "react-router-dom";

import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";

import Index from "views/Index.js";
import Errors from "./views/errors/ErrorsPage"
import Ok from 'views/health/ok';


const App = () => (
	<Switch>
		<Route exact path="/" component={Index} />
		<Route exact path="/health/ok" component={Ok} />
		<Route component={Errors} />
	</Switch>
);

export default App;

